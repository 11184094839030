import {Directive, ElementRef, Input, OnInit} from '@angular/core';
import {FeatureFlagService} from '@twpub/core/services';

@Directive({
  selector: '[pubFeatureOn]'
})
export class FeatureOnDirective implements OnInit {
  @Input('pubFeatureOn') featureName!: string;

  constructor(private el: ElementRef, private featureFlagService: FeatureFlagService) {
  }

  ngOnInit() {
    if (this.featureFlagService.featureOff(this.featureName)) {
      this.el.nativeElement.parentNode.removeChild(this.el.nativeElement);
    }
  }
}
