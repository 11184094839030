import {Component, OnInit} from '@angular/core';
import {ClientConfigurationService} from '@twpub/core/services';
import {ClientConfiguration} from '@twpub/core/utils';
import {ClientProp, PoweredByType} from '@twpub/core/constants';

@Component({
  selector: 'pub-powered-by',
  templateUrl: './powered-by.component.html',
  styleUrls: ['./powered-by.component.scss']
})
export class PoweredByComponent implements OnInit {
  pbType: string = PoweredByType.None;
  powerText: string = 'Powered by TermWebPublish';
  readonly PoweredByType = PoweredByType;
  private clientConfig: ClientConfiguration;

  constructor(private clientConfigService: ClientConfigurationService) {
    this.clientConfig = this.clientConfigService.getCurrentConfig();
  }

  ngOnInit(): void {
    this.pbType = this.clientConfig.props[ClientProp.PoweredByType] || PoweredByType.None;
    this.powerText = this.clientConfig.props[ClientProp.PowerText] || 'Powered by TermWebPublish';
  }
}
