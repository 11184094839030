import {Injectable, Injector} from '@angular/core';
import {NGXLogger} from 'ngx-logger';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {of, throwError} from 'rxjs';
import {url as httpUrl} from '../utils/http-utils';
import {Router} from '@angular/router';
import {PersistentObject} from '@twpub/core/models';

const serverName = `TermWebPublish Server`;
@Injectable({
  providedIn: 'root'
})
export abstract class BaseService {
  protected logger: NGXLogger;
  protected http: HttpClient;
  private router: Router;

  protected constructor(private injectorObj: Injector) {
    this.logger = injectorObj.get(NGXLogger);
    this.http = injectorObj.get(HttpClient);
    this.router = injectorObj.get(Router);
    this.handleError = this.handleError.bind(this);
  }

  protected url(uri: string): string {
    return httpUrl(uri);
  }

  protected handleError(error: HttpErrorResponse) {
    let userMessage = '';
    if (error.status === 0) {
      userMessage = `Unable to connect to ${serverName}.`;
    } else if (error.status === 401) {
      this.logger.info('User session has timed out.')
      this.router.navigate(['/admin']);
      return of();
    } else if (error.status === 404) {
      userMessage = error.message;
    } else if (error.status === 500) {
      userMessage = `Internal error in ${serverName}.`;
    } else if (error.status) {
      userMessage = `Unknown error when connecting to ${serverName}. ${JSON.stringify(error)}`;
    }
    return throwError(() => new Error(userMessage));
  }

  /**
   * Check if an object with the given id exists in the given array of persistent objects.
   * @param id The id.
   * @param objArr The array of persistent objects.
   */
  exists(id: number | undefined, objArr: PersistentObject[]): boolean {
    return objArr.some((p) => p.id === id);
  }
}
