import {Component, Inject, Input} from '@angular/core';
import {ClientConfiguration} from '@twpub/core/utils';
import {NGXLogger} from 'ngx-logger';
import {ClientConfigurationService} from '@twpub/core/services';
import {LOCATION} from '@ng-web-apis/common';

@Component({
  selector: 'pub-configuration-selector',
  templateUrl: './configuration-selector.component.html',
  styleUrls: ['./configuration-selector.component.scss']
})
export class ConfigurationSelectorComponent {
  @Input() configurations: ClientConfiguration[] = [];
  @Input() currentConfigId: number = 0;

  constructor(private logger: NGXLogger, private configService: ClientConfigurationService, @Inject(LOCATION) private location: Location) {
  }

  isVisible(): boolean {
    return this.configurations.length > 1;
  }

  onClick(id: number) {
    this.configService.updateCurrentConfigId(id).subscribe(() => {
      this.currentConfigId = id;
      this.location.reload();
    })
  }
}
