<pub-debug-panel [sessionObj]=sessionObj></pub-debug-panel>
<div id="page-container" *ngIf="isConfigurationOk">
  <pub-header-wrapper [sessionObj]=sessionObj></pub-header-wrapper>
  <div id="page-content" class="twpub-config-{{clientConfig.id}}">
    <pub-preamble-wrapper [sessionObj]=sessionObj></pub-preamble-wrapper>
    <pub-result-view-wrapper [sessionObj]=sessionObj></pub-result-view-wrapper>
  </div>
  <pub-footer-wrapper [sessionObj]=sessionObj></pub-footer-wrapper>
</div>

