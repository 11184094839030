import {Injectable, Injector, PLATFORM_ID} from '@angular/core';
import {isPlatformBrowser} from '@angular/common';
import {ClientProp} from '@twpub/core/constants';
import {SessionObject} from '@twpub/core/models';
import {ClientConfigurationService} from '@twpub/core/services/client-configuration.service';
import {SESSION_STORAGE} from '@ng-web-apis/common';
import {isEqual} from 'lodash';
import {NGXLogger} from 'ngx-logger';
import {PageCompType, SessionParam} from '@twpub/core/enums';
import {ClientConfiguration} from '@twpub/core/utils';

@Injectable({
  providedIn: 'root'
})
export class SessionService {
  private sessionStorage: Storage | undefined;
  private _sessionObj: SessionObject;
  clientConfig!: ClientConfiguration;

  constructor(private injector: Injector, private logger: NGXLogger,
              private clientConfigService: ClientConfigurationService) {
    if (isPlatformBrowser(injector.get(PLATFORM_ID))) {
      this.sessionStorage = injector.get<Storage>(SESSION_STORAGE);
    }
    this.clientConfig = this.clientConfigService.getCurrentConfig();
    const foundLocale = this.clientConfig.props[ClientProp.Locale];
    this._sessionObj = new SessionObject(foundLocale);
  }

  initSession() {
    const storedSession = this.sessionStorage?.getItem('sessionObj');
    this.logger.debug('BasePageComponent.initSession: stored:', {storedSession})
    let sessionObj: SessionObject;
    if (storedSession) {
      sessionObj = JSON.parse(storedSession);
      const defaultValues = this.setDefaultValues(true);
      this.logger.debug('BasePageComponent.initSession: default: ', {defaultValues});
      const defValuesObj = {...sessionObj, ...defaultValues, resultPaginator: sessionObj.resultPaginator};
      if (!isEqual(sessionObj, defValuesObj)) {
        sessionObj = defValuesObj;
      }
    } else {
      sessionObj = this.setDefaultValues();
    }
    this.setSession(sessionObj);
  }

  private saveSession(sessionObj: SessionObject = this._sessionObj) {
    this.sessionStorage?.setItem('sessionObj', JSON.stringify(sessionObj));
  }

  getSession(): SessionObject {
    return this._sessionObj;
  }

  setSession(sessionObj: SessionObject) {
    this._sessionObj = sessionObj;
    this.saveSession(sessionObj);
  }

  setDefaultValues(useFixedValues: boolean = false): SessionObject {
    const foundLocale = this.clientConfig.props[ClientProp.Locale];
    const sessionObj: SessionObject = new SessionObject(foundLocale);
    let defValue: string | number | boolean | undefined;
    const {DictionarySelector, SourceLanguageSelector, TargetLanguageSelector, AdvancedSearch} = PageCompType;
    const defProps = [
      {compType: DictionarySelector, valType: Number, propName: 'dictId'},
      {compType: SourceLanguageSelector, valType: String, propName: 'sourceLangs'},
      {compType: TargetLanguageSelector, valType: String, propName: 'targetLangs'},
      {compType: AdvancedSearch, valType: String, propName: 'additionalField'}
    ];
    sessionObj.version = this.clientConfig.version;
    for (const defProp of defProps) {
      const compConfig = this.clientConfig.getConfigurationSafe(defProp.compType);
      defValue = compConfig?.getConfigValue('defaultValue', defProp.valType);
      const visible = compConfig?.getBoolean('visible');
      if (defValue && (!useFixedValues || !visible)) {
        switch (defProp.propName) {
          case SessionParam.DICT_ID:
            sessionObj[defProp.propName] = defValue as number;
            break;
          case SessionParam.SOURCE_LANGS:
          case SessionParam.TARGET_LANGS:
            sessionObj[defProp.propName] = [defValue as string];
            break;
          case SessionParam.ADDITIONAL_FIELD:
            sessionObj[defProp.propName] = ((defValue as String)?.toLowerCase() === 'true') ? compConfig?.getString('additionalField') : '';
            break;
          default:
            throw new Error('Unknown prop name:' + defProp.propName)
        }
      }
    }
    return sessionObj;
  }
}
