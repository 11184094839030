import {Injectable, Injector} from '@angular/core';
import {ConceptSelection, PaginationData, SessionObject} from '@twpub/core/models';
import {NGXLogger} from 'ngx-logger';
import {SessionService} from '@twpub/core/services/session.service';
import {updateConceptSelection} from '@twpub/core/utils';
import {NavigationService} from '@twpub/core/services/navigation.service';
import _ from 'lodash';
import {DictionaryService} from '@twpub/core/services/dictionary.service';

@Injectable({
  providedIn: 'root'
})
export class SharedStateService {
  constructor(private injector: Injector, private logger: NGXLogger,
              private sessionService: SessionService, private naviService: NavigationService,
              private dictService: DictionaryService) {
  }

  get _sessionObj(): SessionObject {
    return this.sessionService.getSession();
  }

  selectDictionary(dictId: number, concept?: ConceptSelection) {
    if (dictId !== this._sessionObj.dictId) {
      this._sessionObj.dictId = dictId;
      this._sessionObj.sectionOids = []; // todo TPUB-175
      this._sessionObj.conceptId = undefined;
      this._sessionObj.termId = undefined;

      this.dictService.getLanguages(dictId).subscribe({
        next: (langs) => {
          this.logger.trace('SharedStateService.getLanguages:', {langs})
          const dictLangCodes = langs.map(lang => lang.code);
          const sourceLangCodes = _.intersection(this._sessionObj.sourceLangs, dictLangCodes);
          if (sourceLangCodes.length === 0) {
            sourceLangCodes.push(dictLangCodes[0]);
          }
          this.selectSourceLanguages(sourceLangCodes);
          this.selectTargetLanguages(_.intersection(this._sessionObj.targetLangs, dictLangCodes));
          if (concept) {
            this.selectConcept(concept);
          } else {
            this.saveSession();
          }
        }
      })
    } else if (concept) {
      this.selectConcept(concept);
    }
  }

  selectSourceLanguages(langCodes: string[]) {
    if (!_.isEqual(langCodes, this._sessionObj.sourceLangs)) {
      this.logger.trace('SharedStateService.selectSourceLanguages:', {langCodes})
      this._sessionObj.sourceLangs = langCodes;
      this.saveSession();
    }
  }

  selectTargetLanguages(langCodes: string[]) {
    if (!_.isEqual(langCodes, this._sessionObj.targetLangs)) {
      this._sessionObj.targetLangs = langCodes;
      this.saveSession();
    }
  }

  saveSession(sessionObj: SessionObject = this._sessionObj) {
    this.sessionService.setSession(sessionObj);
  }

  selectSections(oids: string[]) {
    if (oids !== this._sessionObj.sectionOids) {
      this._sessionObj.sectionOids = oids;
      this.saveSession();
    }
  }

  setPaginationData(pData: PaginationData) {
    if (pData !== this._sessionObj.resultPaginator) {
      this._sessionObj.resultPaginator = pData;
      this.saveSession();
    }
  }

  selectConcept(selection: ConceptSelection = {}) {
    if (updateConceptSelection(this._sessionObj, selection)) {
      this.saveSession(this._sessionObj);
    }
  }

  selectDomains(ids: number[]) {
    if (ids !== this._sessionObj.domainIds) {
      this._sessionObj.domainIds = ids;
      this.saveSession();
    }
  }

  searchFor(searchTerm: string) {
    this._sessionObj.searchTerm = searchTerm;
    this.clearConcept();
    this.clearPaginationData()
    this.saveSession();
  }

  clearConcept() {
    this._sessionObj.conceptId = undefined;
    this._sessionObj.termId = undefined;
    this.naviService.setUrl(this._sessionObj.dictId);
  }

  clearPaginationData() {
    this._sessionObj.resultPaginator = {
      ...this._sessionObj.resultPaginator,
      pageIndex: 0,
      previousPageIndex: 0,
      totalItems: -1
    }
  }

  includeAdditionalField(field: string) {
    this._sessionObj.additionalField = field;
    this.saveSession();
  }

  getSectionOids = (): string[] => this._sessionObj.sectionOids || [];
  getDictionaryId = (): number | undefined => this._sessionObj.dictId;
  getSearchTerm = (): string | undefined => this._sessionObj.searchTerm;
  getSourceLangs = (): string[] => this._sessionObj.sourceLangs || [];
  getTargetLangs = (): string[] => this._sessionObj.targetLangs || [];
}
