import {Injectable, Injector} from '@angular/core';
import {catchError, Observable} from 'rxjs';
import {ConnectionStatus} from '@twpub/core/models';
import {BaseService} from '@twpub/core/services/base.service';

@Injectable({
  providedIn: 'root'
})
export class TermwebStatusService extends BaseService {
  constructor(private injector: Injector) {
    super(injector)
    this.logger.debug('TermwebStatusService.constructor: ')
  }

  public pingServer(): Observable<String> {
    return this.http.get<String>(this.url('/ping'), {responseType: 'text' as 'json'})
      .pipe(catchError(this.handleError));
  }

  public adminPingServer(): Observable<String> {
    return this.http.get<String>(this.url('/admin-ping'), {withCredentials: true, responseType: 'text' as 'json'})
      .pipe(catchError(this.handleError));
  }

  public getTermWebStatus(): Observable<ConnectionStatus> {
    return this.http.get<ConnectionStatus>(this.url('/status'))
      .pipe(catchError(this.handleError));
  }
}
