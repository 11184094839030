import {Component, Input, OnInit} from '@angular/core';
import {BaseConfigurableComponent} from '@twpub/shared/components/base';
import {
  ClientConfiguration,
  ClientConfigurationScheme,
  ComponentConfiguration,
  ComponentInit,
  InitialConfig
} from '@twpub/core/utils';
import {FieldProps, Header} from '@twpub/core/models';
import {ControlType, PageCompType} from '@twpub/core/enums';

@Component({
  selector: 'pub-default-header',
  templateUrl: './default-header.component.html',
  styleUrls: ['./default-header.component.scss']
})
export class DefaultHeaderComponent extends BaseConfigurableComponent implements OnInit, Header {
  @Input() currentConfigId: number = 0;
  @Input() configurations: ClientConfiguration[] = [];

  showConfigs: boolean = false;

  static Init = class extends ComponentInit {
    readonly scheme = ClientConfigurationScheme.DEFAULT;
    override props: FieldProps[] = [this.VISIBLE_FIELD,
      {key: 'showConfigs', label: 'Show Configuration Selector', controlType: ControlType.Boolean}
    ];

    constructor() {
      super(DefaultHeaderComponent, 'DefaultHeaderComponent', 'Default header', [PageCompType.Header]);
    }

    createConfig(initialConfig: InitialConfig = undefined) {
      return new ComponentConfiguration<Header>(DefaultHeaderComponent, this.name, initialConfig);
    }
  }

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.doUpdate()
  }

  override doUpdate() {
    super.doUpdate();
    this.showConfigs = this.config?.getBoolean('showConfigs', false);
  }
}
