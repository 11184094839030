<ng-container *ngIf="visible">
  <header [ngClass]="cssClassName">
    <div class="logo"><img src="assets/img/twpub_logo2.png" alt="image"></div>
    <div class="config-selector-container" *ngIf="showConfigs">
      <pub-configuration-selector [configurations]="configurations" [currentConfigId]="currentConfigId">
      </pub-configuration-selector>
    </div>
    <div class="header-nav-container">
      <div class="header-nav">
        <a [routerLink]="['/']">Search</a>
        <a [routerLink]="['/admin/home']">Admin</a>
      </div>
    </div>
  </header>
</ng-container>
