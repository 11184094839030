<mat-card appearance="outlined">
  <mat-card-header>
    <mat-card-title>TermWeb Connection</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <ng-container *ngIf="!termwebConfigurations[0]">
      <p>Configure connection to TermWeb</p>
      <form [formGroup]="configForm" *ngIf="!termwebConfigurations[0]">
        <div class="row">
          <div class="col-sm">
            <mat-form-field>
              <mat-label>URL</mat-label>
              <input matInput formControlName="url" required>
              <mat-error *ngIf="configForm.get('url')?.hasError('required')">URL is required</mat-error>
              <mat-error *ngIf="configForm.get('url')?.hasError('pattern')">Enter a valid URL</mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-sm">
            <mat-form-field>
              <mat-label>Client</mat-label>
              <input matInput formControlName="client" required>
              <mat-error *ngIf="configForm.get('client')?.hasError('required')">Client is required</mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-sm">
            <mat-form-field>
              <mat-label>User name</mat-label>
              <input matInput formControlName="userName" required>
              <mat-error *ngIf="configForm.get('userName')?.hasError('required')">User name is required</mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-sm">
            <mat-form-field>
              <mat-label>Password</mat-label>
              <input matInput type="password" formControlName="password" required>
              <mat-error *ngIf="configForm.get('password')?.hasError('required')">Password is required</mat-error>
            </mat-form-field>
          </div>
        </div>
        <button mat-raised-button color="primary" (click)="saveConfiguration()" [disabled]="configForm.invalid">Save
        </button>
      </form>
    </ng-container>
    <mat-accordion *ngIf="termwebConfigurations[0]">
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            <span [ngClass]="'text-' + termwebMessageType">{{ termwebMessage }}</span>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <p>URL: {{ termwebConfigurations[0].url }}</p>
        <p>Client: {{ termwebConfigurations[0].client }}</p>
        <p>Username: {{ termwebConfigurations[0].userName }}</p>
        <!--      <button mat-raised-button color="primary" (click)="clearConfiguration()">Clear</button>-->
      </mat-expansion-panel>
    </mat-accordion>
  </mat-card-content>
</mat-card>
