import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ClientConfigurationScheme, ComponentConfiguration, ComponentInit, InitialConfig} from '@twpub/core/utils';
import {FieldProps, Language, LanguageSelector} from '@twpub/core/models';
import {ControlType, PageCompType} from '@twpub/core/enums';
import {BaseConfigurableComponent} from '@twpub/shared/components/base';

@Component({
  selector: 'pub-language-radio',
  templateUrl: './language-radio.component.html',
  styleUrls: ['./language-radio.component.scss']
})
export class LanguageRadioComponent extends BaseConfigurableComponent implements OnInit, LanguageSelector {
  @Input() currentLanguages?: string[];
  @Input() langs?: Language[];

  @Output() selectLanguages: EventEmitter<string[]> = new EventEmitter<string[]>()

  static Init = class extends ComponentInit {
    readonly scheme = ClientConfigurationScheme.DEFAULT;
    override props: FieldProps[] = [this.LABEL_FIELD, this.VISIBLE_FIELD,
      {key: 'defaultValue', label: 'Default language', controlType: ControlType.Textbox}
    ];

    constructor() {
      super(LanguageRadioComponent, 'LanguageRadioComponent', 'Radio Buttons', [PageCompType.SourceLanguageSelector, PageCompType.TargetLanguageSelector]);
    }

    createConfig(initialConfig: InitialConfig = undefined) {
      return new ComponentConfiguration<LanguageSelector>(LanguageRadioComponent, this.name, initialConfig);
    }
  }

  ngOnInit(): void {
    this.doUpdate();
  }

  onChange(langCode: string) {
    this.selectLanguages.emit([langCode]);
  }

  getLanguageName(lang: Language) {
    return lang.name;
  }
}
