import {Component, Injector, Input, OnChanges, SimpleChanges} from '@angular/core';
import {DictionaryService} from '@twpub/core/services';
import {Language, LanguageSelector} from '@twpub/core/models';
import {hasSessionChanges} from '@twpub/core/utils';
import {PageCompType, SessionParam} from '@twpub/core/enums';
import {BaseWrapperComponent} from '@twpub/shared/components/base';

@Component({
  template: ''
})
export abstract class BaseLanguageWrapperComponent extends BaseWrapperComponent<LanguageSelector> implements OnChanges {
  @Input() currentLanguages?: string[];
  languages?: Language[]

  protected constructor(protected injector: Injector, protected dictService: DictionaryService) {
    super(injector);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (hasSessionChanges(changes, [SessionParam.DICT_ID])) {
      this.fetchLanguages();
    } else {
      this.update();
    }
  }

  private fetchLanguages() {
    const dictId = this.sessionObj?.dictId;
    if (dictId) {
      // const locale = this.sessionObj?.locale;
      this.dictService.getLanguages(dictId).subscribe({
        next: (languages) => {
          this.languages = languages;
          this.update()
        }
      })
    }
  }

  override createComponent(): LanguageSelector {
    const comp = super.createComponent();
    comp.selectLanguages.subscribe((langCodes: string[]) => this.selectLanguages(langCodes));
    this.fetchLanguages()
    return comp;
  }

  protected abstract selectLanguages(langCodes: string[]): void;

  override update() {
    super.update((comp) => {
      const sourceLangs = this.sessionObj?.sourceLangs || [];
      const isSourceLangComponent = this.type === PageCompType.SourceLanguageSelector;
      const hasMultipleSourceLangs = sourceLangs.length > 1;
      const isSourceLang = (code: string) => sourceLangs.includes(code);
      // const filterLangs = (lang: Language) => isSourceLangComponent || hasMultipleSourceLangs || !isSourceLang(lang.code);
      // comp.langs = this.languages?.filter(filterLangs);
      comp.langs = this.languages
      comp.currentLanguages = this.currentLanguages;
    });
  }
}
