import {Injectable, Injector} from '@angular/core';
import {BaseService} from '@twpub/core/services/base.service';
import {catchError, Observable} from 'rxjs';
import {DomainTree} from '@twpub/core/models/domain-tree';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DomainService extends BaseService {
  constructor(private injector: Injector) {
    super(injector);
  }

  public getDomainTree(dictId: number): Observable<DomainTree> {
    return this.http.get<DomainTree>(this.url(`/domains/tree/${dictId}`))
      .pipe(
        map(data => new DomainTree(data)),
        catchError(this.handleError));
  }
}
