import {Component, Injector, OnChanges, OnInit, ViewChild} from '@angular/core';
import {HeaderDirective} from './header.directive';
import {Header} from '@twpub/core/models';
import {PageCompType} from '@twpub/core/enums';
import {ClientConfigurationService} from '@twpub/core/services';
import {ClientConfiguration} from '@twpub/core/utils';
import {BaseWrapperComponent} from '@twpub/shared/components/base';
import {KeycloakService} from 'keycloak-angular';
import {from} from 'rxjs';

@Component({
  selector: 'pub-header-wrapper',
  templateUrl: './header-wrapper.component.html',
  styleUrls: ['./header-wrapper.component.scss']
})
export class HeaderWrapperComponent extends BaseWrapperComponent<Header> implements OnInit, OnChanges {
  override type = PageCompType.Header;

  @ViewChild(HeaderDirective, {static: true}) pubHeader!: HeaderDirective
  configurations: ClientConfiguration[] = [];
  currentConfigId: number = 0;

  constructor(private injector: Injector, private configService: ClientConfigurationService,
              private kcService : KeycloakService) {
    super(injector)
  }

  ngOnInit(): void {
    this.fetchConfigurations();
    this.update();
  }

  ngOnChanges(): void {
    this.update();
  }

  getViewContainerRef() {
    return this.pubHeader.viewContainerRef;
  }

  private fetchConfigurations() {
    from(this.kcService.isLoggedIn()).subscribe(loggedIn => {
      if (loggedIn) {
        this.configurations = this.configService.getConfigurations();
        const configId = this.configService.getCurrentConfigId();
        const configIdExists = this.configurations.findIndex(clientConfig => clientConfig.id === configId) > -1;
        this.currentConfigId = configIdExists ? configId : this.configurations[0]?.id;
      }
    });
  }

  override update() {
    super.update((comp) => {
      comp.currentConfigId = this.currentConfigId;
      comp.configurations = this.configurations;
    })
  }
}
