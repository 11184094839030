import {Component, Injector, OnInit, ViewChild} from '@angular/core';
import {SearchPage} from '@twpub/core/models/wrappers/search-page';
import {PageCompType} from '@twpub/core/enums';
import {SearchPageDirective} from './search-page.directive';
import {BaseWrapperComponent} from '@twpub/shared/components/base';

@Component({
  selector: 'pub-search-wrapper-page',
  templateUrl: './search-page-wrapper.component.html',
  styleUrls: ['./search-page-wrapper.component.scss']
})
export class SearchPageWrapperComponent extends BaseWrapperComponent<SearchPage> implements OnInit {
  override type = PageCompType.SearchPage;

  @ViewChild(SearchPageDirective, {static: true}) pubSearchPage!: SearchPageDirective

  constructor(private injector: Injector) {
    super(injector);
  }

  ngOnInit(): void {
    this.update();
  }

  getViewContainerRef() {
    return this.pubSearchPage.viewContainerRef;
  }

  override createComponent(): SearchPage {
    const comp = super.createComponent();
    comp.config = this.componentConfig;
    comp.pageCompType = this.type;
    return comp;
  }
}
