import {Injectable, Injector} from '@angular/core';
import {mergeMap, Observable, tap} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {ClientConfiguration, ClientConfigurationScheme} from '../utils';
import {AuthConfiguration, ClientConfigurationDto, TermwebConfiguration} from '../models';
import {BaseService} from './base.service';
import {ComponentDeclarationService} from './component-declaration.service';
import {SynchronizeDictionariesTaskStatus} from '@twpub/core/models/synchronize-dictionaries-task-status';

@Injectable({
  providedIn: 'root'
})
export class ClientConfigurationService extends BaseService {
  private configurations: ClientConfiguration[] = [];
  private currentConfigId: number = 0;

  constructor(private injector: Injector, private compDeclarationService: ComponentDeclarationService) {
    super(injector);
    this.logger.debug('<< ClientConfigurationService constr')
  }

  loadConfigurations() {
    return new Observable((subscriber) => {
      this.logger.debug('>> loadConfigurations')
      this.fetchConfigurations().pipe(
        mergeMap(() => this.fetchCurrentConfigId())
      ).subscribe({
        next: () => subscriber.complete()
      })
    });
  }

  updateAndFetchConfigurations(config: ClientConfiguration) {
    if (!config.id) {
      config.id = this.findNewId();
    }
    return this.updateConfiguration(config).pipe(mergeMap(() => this.fetchConfigurations())
    );
  }

  private findNewId() {
    return this.configurations.reduce((max, config) => config.id > max ? config.id : max, 0) + 1;
  }

  private updateConfiguration(config: ClientConfiguration): Observable<any> {
    return this.http.put(this.url('/configurations'), config.toObject())
      .pipe(catchError(this.handleError));
  }

  private fetchConfigurations(): Observable<ClientConfiguration[]> {
    return this.http.get<ClientConfigurationDto[]>(this.url('/configurations'))
      .pipe(
        map((dtoArr) => dtoArr.map((dto) => this.fromDto(dto))),
        tap((configs) => {
          this.configurations = configs;
          this.logger.debug('fetchConfigurations configs=' + JSON.stringify(configs))
        }),
        catchError(this.handleError)
      );
  }

  private fetchCurrentConfigId(): Observable<number> {
    return this.http.get<number>(this.url('/configurations/current-id'))
      .pipe(
        tap((currentConfigId) => {
          this.currentConfigId = currentConfigId;
        }),
        catchError(this.handleError));
  }

  fromDto(dto: ClientConfigurationDto) {
    const config = new ClientConfiguration(dto.id, dto.name, dto.scheme || ClientConfigurationScheme.DEFAULT);
    config.css = dto.css;
    config.scheduledSyncHours = dto.scheduledSyncHours;
    config.props = dto.props;
    Object.entries(dto.componentConfig).forEach(([compType, compConfigDto]) => {
      const compConfig = this.compDeclarationService.createComponentConfiguration(compConfigDto.componentType);
      if (compConfig) {
        Object.entries(compConfigDto.config).forEach(([key, value]) => {
          compConfig.setConfigValue(key, value);
        })
        config.setConfiguration(compType, compConfig)
      }
    })
    return config;
  }

  updateCurrentConfigId(id: number): Observable<any> {
    this.currentConfigId = id;
    return this.http.put(this.url('/configurations/current-id/' + id), {id})
      .pipe(catchError(this.handleError));
  }

  getConfigurations = (): ClientConfiguration[] => this.configurations;
  getCurrentConfig = (): ClientConfiguration => this.configurations.find(config => config.id === this.currentConfigId) || this.configurations[0];
  getCurrentConfigId = (): number => this.currentConfigId;
  getIdByName = (name: string): number | undefined => this.configurations.find(config => config.name.toLowerCase() === name.toLowerCase())?.id;

  saveTermWebConfiguration(config: TermwebConfiguration): Observable<any> {
    return this.http.put(this.url('/configurations/termweb'), config)
      .pipe(catchError(this.handleError));
  }

  getTermWebConfigurations(): Observable<TermwebConfiguration[]> {
    return this.http.get<TermwebConfiguration[]>(this.url('/configurations/termweb'))
      .pipe(catchError(this.handleError));
  }

  getAuthConfiguration(): Observable<AuthConfiguration> {
    return this.http.get<AuthConfiguration>(this.url('/configurations/auth'))
      .pipe(catchError(this.handleError));
  }

  hasTermWebConfiguration(): Observable<any> {
    return this.http.get<TermwebConfiguration[]>(this.url('/configurations/termweb'))
      .pipe(
        map((configs) => {
          if (!configs?.length) {
            throw new Error('No TermWeb connection configured')
          }
        }),
        catchError(this.handleError)
      );
  }

  public getScheduledSyncStatus(): Observable<SynchronizeDictionariesTaskStatus> {
    return this.http.get<SynchronizeDictionariesTaskStatus>(this.url('/configurations/scheduled-status'))
      .pipe(catchError(this.handleError));
  }

  deleteConfig(id: number) {
    return this.http.delete(this.url('/configurations/' + id))
      .pipe(
        tap(() => {
          this.configurations = this.configurations.filter(config => config.id !== id);
          if (this.currentConfigId === id) {
            this.currentConfigId = this.configurations[0]?.id || 0;
          }
        }),
        catchError(this.handleError));
  }
}
