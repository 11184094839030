import {NgModule} from '@angular/core';
import {CommonModule, NgOptimizedImage} from '@angular/common';
import {RouterModule} from '@angular/router';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ConfigurationSelectorComponent, DebugPanelComponent, ExternalRedirectComponent} from './components';
import {FeatureOnDirective} from './directives/feature-on.directive';
import {FeatureOffDirective} from './directives/feature-off.directive';
import {MatSelectModule} from '@angular/material/select';
import {PoweredByComponent} from '@twpub/shared/components';
import {PrettyDatePipe} from './pipes/pretty-date.pipe';
import {SanitizePipe} from './pipes/sanitize.pipe';
import {MatTooltipModule} from '@angular/material/tooltip';
import {ConceptViewDialogComponent} from '@twpub/shared/components/concept-view-dialog/concept-view-dialog.component';
import {
  A11yPageDirective,
  A11yPageWrapperComponent,
  AdvancedSearchDirective,
  AdvancedSearchWrapperComponent,
  AttachmentRenderDirective,
  AttachmentRenderWrapperComponent,
  DictionarySelectorDirective,
  DictionaryWrapperComponent,
  DomainSelectorDirective,
  DomainWrapperComponent,
  FooterDirective,
  FooterWrapperComponent,
  HeaderDirective,
  HeaderWrapperComponent,
  PreambleDirective,
  PreambleWrapperComponent,
  ResultListDirective,
  ResultListWrapperComponent,
  ResultPageDirective,
  ResultPageWrapperComponent,
  ResultViewDirective,
  ResultViewWrapperComponent,
  SearchFieldDirective,
  SearchFieldWrapperComponent,
  SearchPageDirective,
  SearchPageWrapperComponent,
  SectionSelectorDirective,
  SectionWrapperComponent,
  SourceLanguageSelectorDirective,
  SourceLanguageWrapperComponent,
  TargetLanguageSelectorDirective,
  TargetLanguageWrapperComponent
} from '@twpub/shared/components/wrappers';
import {
  FieldRenderComponent,
  InternalLinkRenderComponent,
  LinkRenderComponent,
  TermRenderComponent
} from '@twpub/shared/components/renders';
import {GallerizeDirective} from 'ng-gallery/lightbox';
import {MatIconModule} from '@angular/material/icon';
import {
  VirtualFileContentDialogComponent
} from './components/renders/virtual-file-content-dialog/virtual-file-content-dialog.component';
import {MatDialogModule} from '@angular/material/dialog';
import {MatButtonModule} from '@angular/material/button';
import {DomainBreadcrumbsComponent} from './components/domain-breadcrumbs/domain-breadcrumbs.component';

@NgModule({
  declarations: [
    AdvancedSearchWrapperComponent, AdvancedSearchDirective, DebugPanelComponent, DictionaryWrapperComponent,
    DictionarySelectorDirective, FeatureOnDirective, FeatureOffDirective, HeaderWrapperComponent, HeaderDirective,
    FooterWrapperComponent, FooterDirective, PreambleWrapperComponent, PreambleDirective, SearchFieldWrapperComponent,
    SearchFieldDirective, SourceLanguageWrapperComponent, SourceLanguageSelectorDirective,
    TargetLanguageWrapperComponent, TargetLanguageSelectorDirective, ResultListWrapperComponent, ResultListDirective,
    ResultViewWrapperComponent, ResultViewDirective, SearchPageWrapperComponent, SearchPageDirective,
    ResultPageWrapperComponent, ResultPageDirective, A11yPageWrapperComponent, A11yPageDirective,
    ConfigurationSelectorComponent, SectionWrapperComponent, SectionSelectorDirective, PoweredByComponent,
    PrettyDatePipe, SanitizePipe, ExternalRedirectComponent, TermRenderComponent, FieldRenderComponent,
    LinkRenderComponent, ConceptViewDialogComponent, InternalLinkRenderComponent, VirtualFileContentDialogComponent,
    AttachmentRenderWrapperComponent, AttachmentRenderDirective, DomainWrapperComponent, DomainSelectorDirective,
    DomainBreadcrumbsComponent
  ],
  imports: [
    CommonModule, RouterModule, FormsModule, ReactiveFormsModule, MatSelectModule, NgOptimizedImage,
    MatTooltipModule, GallerizeDirective, MatIconModule, MatDialogModule, MatButtonModule
  ],
  exports: [CommonModule, RouterModule, FormsModule, ReactiveFormsModule, AdvancedSearchWrapperComponent,
    DebugPanelComponent, FeatureOnDirective, FeatureOffDirective,
    HeaderWrapperComponent, FooterWrapperComponent, PreambleWrapperComponent,
    DictionaryWrapperComponent, SearchFieldWrapperComponent, ResultViewWrapperComponent,
    SourceLanguageWrapperComponent, TargetLanguageWrapperComponent, ResultListWrapperComponent,
    ConfigurationSelectorComponent, SectionWrapperComponent, PoweredByComponent, PrettyDatePipe,
    TermRenderComponent, FieldRenderComponent, LinkRenderComponent, DomainWrapperComponent,
    AttachmentRenderWrapperComponent, DomainBreadcrumbsComponent
  ]
})
export class SharedModule {
}
