<div id="page-container" *ngIf="isConfigurationOk">
  <div id="page-content" class="twpub-config-{{clientConfig.id}}">
    <!--start header-->
    <pub-header-wrapper [sessionObj]=sessionObj></pub-header-wrapper>
    <!--end header-->

    <digi-typography>
      <digi-layout-block afVerticalPadding="true">
        <digi-layout-container>
          <digi-typography-heading-jumbo [afText]="title" afLevel="h1"></digi-typography-heading-jumbo>
        </digi-layout-container>
      </digi-layout-block>
      <digi-layout-block afVerticalPadding="true">
        <digi-layout-container>
          <digi-typography-preamble>
            Arbetsförmedlingen är ägare till den här webbplatsen. Vi vill att alla ska kunna använda webbplatsen,
            oavsett behov.
            Här redogör vi hur
            <digi-link>
              <a href="https://arbetsformedlingen.termbank.se">https://arbetsformedlingen.termbank.se</a>
            </digi-link>
            uppfyller lagen om tillgänglighet till digital offentlig service, eventuella kända tillgänglighetsproblem
            och hur du kan rapportera brister till oss så att vi kan åtgärda dem.
          </digi-typography-preamble>
          <h2>Hur tillgänglig är webbplatsen?</h2>

          <p>Vi är medvetna om att delar av webbplatsen inte är helt tillgängliga. I avsnittet om innehåll som inte är
            tillgängligt listar vi kända brister i tillgänglighet.</p>

          <h2> Kontakta oss om du hittar fler brister</h2>

          <p>Vi strävar hela tiden efter att förbättra webbplatsens tillgänglighet. Om du upptäcker problem som inte är
            beskrivna på den här sidan, eller om du anser att vi inte uppfyller lagens krav, meddela oss så att vi får
            veta att problemet finns.</p>

          <p>
            <digi-link-external>
              <a href="https://arbetsformedlingen.se/synpunkter-tillganglighet">Lämna synpunkter på digital
                tillgänglighet (länken leder till arbetsformedlingen.se)</a>
            </digi-link-external>
          </p>

          <h2>Kontakta tillsynsmyndigheten</h2>

          <p>Myndigheten för digital förvaltning har ansvaret för tillsyn för lagen om tillgänglighet till digital
            offentlig service. Om du inte är nöjd med hur vi hanterar dina synpunkter kan du kontakta Myndigheten för
            digital förvaltning och berätta det.</p>

          <h2>Teknisk information om webbplatsens tillgänglighet</h2>

          <p>Den här webbplatsen är delvis förenlig med lagen om tillgänglighet till digital offentlig service, på grund
            av de brister som beskrivs nedan.</p>

          <h3>Innehåll som inte är tillgängligt</h3>

          <p>Vi är medvetna om följande brister som inte följer lagkraven:</p>
          <ul>
            <li>Webbplatsens semantiska sidstruktur är inte korrekt hanterad i koden.</li>

            <li>Sökknappens ram uppfyller inte tillräckliga krav för kontrast mot vit bakgrund.</li>

            <li>Länken för sökresultatord och länken nedan för att visa mer om termen leder till samma sida.</li>

            <li>Tabellen som skapas efter sökning är oregelbunden. Denna väg skapades för att förtydliga att det finns
              mer information att läsa om termen. På grund av oregelbundet resultat och förvirring kommer länken för
              sökresultatord att tas bort.
            </li>

            <li>Ikoner som används för att visa att länkar går till externa sidor har ingen textbeskrivning.</li>

            <li>Det finns endast ett sätt att hitta en term och det är genom att söka på den. Sökfunktionen kan idag
              inte hantera stavfel. En planerad åtgärd är att implementera ett bokstavsindex för att utöka
              sökmöjligheterna.
            </li>

            <li>Sidtitlar för vissa termsidor är omvänd så att sidans unika titel kommer efter Arbetsförmedlingen.</li>

            <li>Tabellceller med ord på annat språk än svenska har inte märkts upp i koden vilket gör det otydligt att
              cellen innehåller text på språk som avviker från sidans huvudspråk.
            </li>

            <li>Länkar går inte att aktivera med tangentbordsnavigation. Detta beror på en bugg i koden.</li>

            <li>Länkar som beskrivs som ”Visa hela termposten” innehåller ingen information i om vilken termsida som
              länken leder till.
            </li>

            <li>Vid sökning på en term får användare som navigerar med skärmläsare ingen återkoppling om status för
              sökningen; att den är utförd, hur många träffar som visas eller om sökningen inte gett några resultat
              alls.
            </li>

            <li>Tabellen som innehåller termen saknar en beskrivande titel och det framkommer inte i koden om en
              termsökning inte givit några sökträffar.
            </li>

            <li>Vid validering av webbplatsens kod finns det element som inte stängs och termtabellen är oregelbunden.
            </li>

            <li>Sökresultatet saknar paginering vilket beroende på sökning kan resultera i en mycket lång sökning.</li>
          </ul>

          <p>Samtliga brister är påtalade till leverantören och Arbetsförmedlingen inväntar uppdatering.</p>


          <p>Vår ambition är att ha åtgärdat alla kända tillgänglighetsproblem före 2024-01-31.</p>

          <h2>Hur vi har testat webbplatsen</h2>

          <p>Vi har gjort en självskattning (intern testning) av https://arbetsformedlingen.termbank.se</p>

          <p>Senaste bedömningen gjordes den 2023-03-28</p>

          <p>Redogörelsen uppdaterades den 2023-03-28.</p>

          <h2>Hur vi jobbar med digital tillgänglighet</h2>

          <p>Vi strävar efter att Arbetsförmedlingens webbplatser ska kunna uppfattas, hanteras och förstås av alla
            användare, oavsett behov eller funktionsnedsättning och oberoende av vilka hjälpmedel du använder. Vi ska
            åtminstone uppnå grundläggande tillgänglighet genom att följa
            <digi-link-external>
              <a href="https://webbriktlinjer.se/riktlinjer/1-utga-fran-wcag-2-1-niva-aa/">WCAG 2.1 på nivå AA.</a>
            </digi-link-external>
          </p>

          <p>Ett arbete pågår för att se över Arbetsförmedlingens digitala riktlinjer. För att ge våra medarbetare rätt
            förutsättningar och kunskap om digital tillgänglighet lägger vi stort fokus på att utbilda dem som utvecklar
            våra digitala tjänster.</p>
        </digi-layout-container>
      </digi-layout-block>
    </digi-typography>
  </div>
  <!--start footer-->
  <pub-footer-wrapper [sessionObj]=sessionObj></pub-footer-wrapper>
  <!-- end footer-->
</div>
