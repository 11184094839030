import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {ClientConfigurationService} from '@twpub/core/services';
import {ClientConfiguration, ClientConfigurationScheme} from '@twpub/core/utils';
import {NGXLogger} from 'ngx-logger';
import {ConfirmDialogComponent} from '../confirm-dialog/confirm-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {ClientConfigurationDto} from '@twpub/core/models';
import {UploadDialogComponent} from '../confirm-dialog/upload-dialog/upload-dialog.component';
import {environment} from '../../../../environments/environment';

/**
 * Container for various components
 */
@Component({
  selector: 'pub-configuration-list',
  templateUrl: './configuration-list.component.html',
  styleUrls: ['./configuration-list.component.scss']
})
export class ConfigurationListComponent implements OnInit {
  @Output() setEditConfig = new EventEmitter<ClientConfiguration>();

  configurations: ClientConfiguration[] = [];
  currentConfigId: number = 0;
  displayedColumns: string[] = ['name', 'selected', 'actions'];

  constructor(private logger: NGXLogger, private dialog: MatDialog, private configService: ClientConfigurationService) {
  }

  ngOnInit(): void {
    this.fetchConfigurations();
  }

  private fetchConfigurations() {
    const allowedSchemes = environment.configurationSchemes || [ClientConfigurationScheme.DEFAULT];
    this.logger.debug('ConfigurationListComponent.fetchConfigurations:', {allowedSchemes})
    this.configurations = this.configService.getConfigurations().filter(config => allowedSchemes.includes(config.scheme));
    this.logger.debug('ConfigurationListComponent.fetchConfigurations:', {configs: this.configurations})
    const configId = this.configService.getCurrentConfigId();
    const configIdExists = this.configurations.findIndex(clientConfig => clientConfig.id === configId) > -1;
    this.currentConfigId = configIdExists ? configId : this.configurations[0]?.id;
  }

  editConfig(id: number) {
    const config = this.getConfig(id);
    this.setEditConfig.emit(config?.clone());
  }

  copyConfig(id: number) {
    const config = this.getConfig(id);
    if (config) {
      const newConfig = config.clone();
      newConfig.id = 0;
      newConfig.name = this.findUniqueName(config);
      this.setEditConfig.emit(newConfig);
    }
  }

  private findUniqueName(config: ClientConfiguration) {
    let count = 0;
    let name: string;
    do {
      count++;
      const newSuffix = ' (' + count + ')';
      name = config.name + newSuffix;
    } while (this.configService.getIdByName(name));
    return name;
  }

  selectConfig(id: number) {
    this.configService.updateCurrentConfigId(id).subscribe(() => {
      this.currentConfigId = id;
    })
  }

  createConfig() {
    const config = new ClientConfiguration(0, '', ClientConfigurationScheme.DEFAULT);
    this.setEditConfig.emit(config);
  }

  deleteConfig(id: number) {
    const config = this.getConfig(id);
    const message: string[] = ['Are you sure you want to delete the configuration "' + config?.name + '"?', 'This action cannot be undone.'];
    const confirmDialogRef = this.dialog.open(ConfirmDialogComponent, {data: {message, confirmLabel: 'Delete'}});

    confirmDialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.configService.deleteConfig(id).subscribe(() => {
          this.configurations = this.configurations.filter(config => config.id !== id);
        })
      }
    });
  }

  downloadConfig(id: number) {
    const config = this.getConfig(id);
    if (config) {
      const dataStr = 'data:text/json;charset=utf-8,' + encodeURIComponent(JSON.stringify(config.toObject()));
      const a = document.createElement('a');
      a.href = dataStr;
      a.download = `twpubConfig-${config.name}.json`;
      a.click();
    }
  }

  importConfig() {
    const confirmDialogRef = this.dialog.open(UploadDialogComponent, {
      data: {
        message: ['Please select a configuration file to upload.'],
        confirmLabel: 'Upload',
        cancelLabel: 'Cancel'
      }
    });

    confirmDialogRef.afterClosed().subscribe((file: File) => {
      if (file) {
        this.handleFileUpload(file);
      }
    });
  }

  private handleFileUpload(file: File): void {
    const reader = new FileReader();
    reader.onload = () => {
      try {
        const configDto = JSON.parse(reader.result as string) as ClientConfigurationDto;
        const config = this.configService.fromDto(configDto);
        config.id = 0;
        if (this.configService.getIdByName(config.name)) {
          config.name = this.findUniqueName(config);
        }
        this.setEditConfig.emit(config);
      } catch (error) {
        this.logger.error('Failed to parse configuration file', error);
      }
    };
    reader.readAsText(file);
  }

  private getConfig = (id: number) => this.configurations.find(config => config.id === id);

  isSelected = (config: ClientConfiguration) => config.id === this.currentConfigId;
}
