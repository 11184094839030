<ng-container *ngIf="visible" class="container">
  <div class="language-radio-div {{cssClassName}}">
    <label>{{label}}</label>
    <mat-button-toggle-group appearance="standard" [value]="currentLanguages?.[0]" (change)="onChange($event.value)">
      <ng-container *ngFor="let lang of langs">
        <mat-button-toggle *ngIf="lang.code !== ''" [value]="lang.code">{{getLanguageName(lang)}}</mat-button-toggle>
      </ng-container>
    </mat-button-toggle-group>
  </div>
</ng-container>
