<ng-container [ngSwitch]="status">
  <mat-icon *ngSwitchCase="'IDLE'" matTooltipPosition="right" [matTooltip]="'Not synchronized'"
            class="material-icons-outlined">pending
  </mat-icon>
  <mat-spinner *ngSwitchCase="'IN_PROGRESS'" matTooltipPosition="right" [matTooltip]="'Working...'"
               [diameter]="24"></mat-spinner>
  <mat-icon *ngSwitchCase="'COMPLETE'" matTooltipPosition="right" [matTooltip]="'Synchronized'" class="complete-icon">
    done
  </mat-icon>
  <mat-icon *ngSwitchCase="'WARNING'" matTooltipPosition="right" [matTooltip]="message"
            class="warning-icon">warning
  </mat-icon>
  <mat-icon *ngSwitchCase="'FAILED'" matTooltipPosition="right" [matTooltip]="'Synchronization failed'"
            class="failed-icon">error
  </mat-icon>
</ng-container>
