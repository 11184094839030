import {Injectable} from '@angular/core';

type FeatureFlags = { [name: string]: boolean }

@Injectable({
  providedIn: 'root'
})
export class FeatureFlagService {
  features: FeatureFlags = {
    // debug: true
  }

  featureOff(featureName: string) {
    // Read the value from the config service
    // const features = AppConfig.settings.features;
    // if (features.hasOwnProperty(featureName)) {
    const feature = this.features[featureName];
    return !feature;
    // }
    // return true; // if feature not found, default to turned off
  }

  featureOn(featureName: string) {
    return !this.featureOff(featureName);
  }
}
