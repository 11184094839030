import {Component, Input} from '@angular/core';
import {SessionObject} from '@twpub/core/models';

@Component({
  selector: 'pub-debug-panel',
  templateUrl: './debug-panel.component.html',
  styleUrls: ['./debug-panel.component.scss']
})
export class DebugPanelComponent {
  @Input() sessionObj?: SessionObject;
}
