import {Injectable, Injector} from '@angular/core';
import {catchError, Observable} from 'rxjs';
import {BaseService} from './base.service';
import {SearchResultConfig} from '../constants';
import {PaginationData, SearchCriteria} from '../models';
import {PagedTermSearchResult} from '../models/paged-term-search-result';

@Injectable({
  providedIn: 'root'
})
export class SearchService extends BaseService {
  constructor(private injector: Injector) {
    super(injector);
  }

  /**
   * Performs a search request to twpub server and returns an Observable with the paged term search result.
   *
   * @param criteria Search criteria object
   * @param resultConfig Configuration object for result data
   * @param pData Pagination data object
   */
  public search(criteria: SearchCriteria, resultConfig: SearchResultConfig, pData?: PaginationData): Observable<PagedTermSearchResult> {
    const pageSize = pData?.pageSize || 0;
    const total = pData?.totalItems || -1;
    const pageIndex = pData?.pageIndex || 0;
    const prevPageIndex = pData?.previousPageIndex || 0;
    const searchReq = {
      criteria,
      paginationData: {
        pageSize,
        pageIndex,
        prevPageIndex,
        total
      },
      resultConfig
    };
    this.logger.debug('SearchService.search: ', searchReq)
    return this.http.post<PagedTermSearchResult>(this.url('/search'), searchReq)
      .pipe(catchError(this.handleError));
  }
}
