import {Component, Injector, ViewChild} from '@angular/core';
import {TargetLanguageSelectorDirective} from './target-language-selector.directive';
import {DictionaryService} from '@twpub/core/services';
import {PageCompType} from '@twpub/core/enums';
import {BaseLanguageWrapperComponent} from '@twpub/shared/components/base';

@Component({
  selector: 'pub-target-language-wrapper',
  templateUrl: './target-language-wrapper.component.html',
  styleUrls: ['./target-language-wrapper.component.scss']
})
export class TargetLanguageWrapperComponent extends BaseLanguageWrapperComponent {
  override type = PageCompType.TargetLanguageSelector;
  @ViewChild(TargetLanguageSelectorDirective, {static: true}) pubTgtLangSelect!: TargetLanguageSelectorDirective

  constructor(injector: Injector, dictService: DictionaryService) {
    super(injector, dictService);
  }

  getViewContainerRef() {
    return this.pubTgtLangSelect.viewContainerRef;
  }

  protected selectLanguages(langCodes: string[]): void {
    return this.sharedStateService.selectTargetLanguages(langCodes);
  }
}
