import {Component, OnInit} from '@angular/core';
import {TermwebConfiguration} from '@twpub/core/models';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {NGXLogger} from 'ngx-logger';
import {ClientConfigurationService, TermwebStatusService} from '@twpub/core/services';
import {HttpErrorResponse} from '@angular/common/http';

@Component({
  selector: 'pub-termweb-connection-settings',
  templateUrl: './termweb-connection-settings.component.html',
  styleUrls: ['./termweb-connection-settings.component.scss']
})
export class TermwebConnectionSettingsComponent implements OnInit {
  termwebConfigurations: TermwebConfiguration[] = [];
  termwebMessageType?: string;
  termwebMessage?: string;

  configForm: UntypedFormGroup = this.fb.group({
    url: ['', [Validators.required, Validators.pattern('^(https?)://[^\\s/$.?#].[^\\s]*$')]],
    client: ['', Validators.required],
    userName: ['', Validators.required],
    password: ['', Validators.required]
  });

  constructor(private logger: NGXLogger, private fb: UntypedFormBuilder, private configService: ClientConfigurationService,
              private statusService: TermwebStatusService) {
  }

  ngOnInit(): void {
    this.fetchConfigurations();
    this.getTermWebStatus();
  }

  private fetchConfigurations() {
    this.configService.getTermWebConfigurations().subscribe({
      next: (configs) => {
        this.termwebConfigurations = configs;
      }
    });
  }

  private getTermWebStatus() {
    this.statusService.getTermWebStatus().subscribe({
      next: status => {
        if (status.ok) {
          this.termwebMessageType = 'success'
          this.termwebMessage = 'TermWeb connection successful.';
        } else {
          this.termwebMessageType = 'danger'
          this.termwebMessage = 'TermWeb connection failed: ' + status.message;
        }
      },
      error: (error) => this.handleError(error)
    });
  }

  private handleError(error: HttpErrorResponse) {
    this.termwebMessageType = 'danger'
    this.termwebMessage = error.message;
    this.logger.error('Connection error', error.message);
  }

  saveConfiguration() {
    if (this.configForm.valid) {
      const newConfig: TermwebConfiguration = this.configForm.value;
      this.configService.saveTermWebConfiguration(newConfig).subscribe({
        next: () => {
          this.configService.getTermWebConfigurations().subscribe({
            next: (configs) => {
              this.termwebConfigurations = configs;
            }
          });
          this.configForm.reset();
        }
      });
    }
  }
}
