<digi-layout-container afVerticalPadding="true" *ngIf="!!results">
  <digi-typography>
    <h2>Sökresultat</h2>
    <ng-container *ngIf="isWaiting">
      <digi-loader-spinner></digi-loader-spinner>
    </ng-container>
    <ng-container *ngIf="!isWaiting">
      <digi-table *ngIf="results?.length" afSize="small">
        <table>
          <thead>
          <tr>
            <th>Rekommenderad term</th>
            <th *ngIf="includeSynonyms">Synonym</th>
            <ng-container *ngFor="let fieldName of fieldNames">
              <th>{{fieldName}}</th>
            </ng-container>
            <th *ngIf="includeTranslations">{{targetLangName}}</th>
          </tr>
          </thead>
          <tbody infinite-scroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="1000"
                 (scrolled)="onScrollDown()">
          <ng-container *ngFor="let res of results; let i=index">
            <tr class="tr1" #resultItem id="res-{{i}}">
              <td>
                <digi-link [afLinkContainer]="true">
                  <a [routerLink]="['/entry',res.term.conceptId, res.term.id]"
                     (click)="resultClick(res, i)">{{ res.term.name }}</a>
                </digi-link>
              </td>
              <td *ngIf="includeSynonyms">
                <ng-container *ngFor="let synonym of res.synonyms">
                  {{synonym.name}}<br>
                </ng-container>
              </td>
              <ng-container *ngFor="let fieldName of fieldNames">
                <td>{{getFieldValue(res, fieldName)}}</td>
              </ng-container>
              <td *ngIf="includeTranslations">{{res.translations?.[0]?.name}}</td>
            </tr>
            <tr *ngIf="includeResultLink" class="tr2">
              <td colspan="5">
                <digi-link [afLinkContainer]="true">
                  <a [routerLink]="['/entry',res.term.conceptId, res.term.id]">
                    <digi-icon-chevron-right slot="icon"></digi-icon-chevron-right>
                    {{ getLinkText(res.term.name) }}</a>
                </digi-link>
              </td>
            </tr>
          </ng-container>
          </tbody>
        </table>
      </digi-table>
      <p *ngIf="hasNoResults()">Inga träffar på "{{sessionObj.searchTerm}}".</p>
    </ng-container>
  </digi-typography>
</digi-layout-container>
