import {Component, OnInit} from '@angular/core';
import {ClientConfigurationService} from '@twpub/core/services';
import {NGXLogger} from 'ngx-logger';
import {SynchronizeDictionariesTaskStatus} from '@twpub/core/models/synchronize-dictionaries-task-status';

@Component({
  selector: 'pub-scheduled-sync-settings',
  templateUrl: './scheduled-sync-settings.component.html',
  styleUrls: ['./scheduled-sync-settings.component.scss']
})
export class ScheduledSyncSettingsComponent implements OnInit {
  updateIntervals = [
    {value: 0, viewValue: 'No automatic updates'},
    {value: 1, viewValue: 'Every hour'},
    {value: 2, viewValue: 'Every 2 hours'},
    {value: 4, viewValue: 'Every 4 hours'},
    {value: 8, viewValue: 'Every 8 hours'},
    {value: 12, viewValue: 'Every 12 hours'},
    {value: 24, viewValue: 'Every day'},
    {value: 48, viewValue: 'Every 2 days'},
    {value: 168, viewValue: 'Once a week'}
  ];

  selectedInterval: number = 0;
  scheduledStatus?: SynchronizeDictionariesTaskStatus;

  constructor(private logger: NGXLogger, private configService: ClientConfigurationService) {
  }

  ngOnInit(): void {
    this.selectedInterval = this.configService.getCurrentConfig().scheduledSyncHours;
    this.logger.debug('ScheduledSyncSettingsComponent.ngOnInit: ' + this.selectedInterval);
    this.getScheduledStatus();
  }

  onSelectionChange(): void {
    this.logger.debug('ScheduledSyncSettingsComponent.onSelectionChange: ' + this.selectedInterval);
    const currentConfig = this.configService.getCurrentConfig();
    currentConfig.scheduledSyncHours = this.selectedInterval;
    this.configService.updateAndFetchConfigurations(currentConfig).subscribe({next: () => this.getScheduledStatus()});
  }

  private getScheduledStatus() {
    this.configService.getScheduledSyncStatus().subscribe({
      next: (status) => {
        this.logger.debug('ScheduledSyncSettingsComponent.next: ', {status});
        this.scheduledStatus = status;
      }
    });
  }
}
