import {AfterContentInit, Component, ComponentRef, Injector, Input, ViewContainerRef} from '@angular/core';
import {NGXLogger} from 'ngx-logger';
import {ClientConfiguration, ComponentConfiguration} from '@twpub/core/utils';
import {PageCompType} from '@twpub/core/enums';
import {ConfigurableComponent, SessionObject} from '@twpub/core/models';
import {ClientConfigurationService, ComponentDeclarationService, SharedStateService} from '@twpub/core/services';

@Component({
  template: ''
})

export abstract class BaseWrapperComponent<T extends ConfigurableComponent> implements AfterContentInit {
  protected abstract type: PageCompType
  protected logger: NGXLogger
  protected clientConfigService: ClientConfigurationService
  protected sharedStateService: SharedStateService;
  protected componentConfig?: ComponentConfiguration<T>
  private componentDeclarationService: ComponentDeclarationService;
  protected componentRef?: ComponentRef<T>

  visible: boolean = true

  private readonly clientConfig: ClientConfiguration
  /**
   * Not used, but required for component to update properly.
   */
  @Input() sessionObj!: SessionObject;

  constructor(private injectorObj: Injector) {
    this.logger = injectorObj.get(NGXLogger);
    this.clientConfigService = injectorObj.get(ClientConfigurationService);
    this.clientConfig = this.clientConfigService.getCurrentConfig();
    this.componentDeclarationService = injectorObj.get(ComponentDeclarationService);
    this.sharedStateService = injectorObj.get(SharedStateService);
  }

  ngAfterContentInit() {
    this.loadComponent()
  }

  loadComponent() {
    this.getViewContainerRef().clear()
    if (this.clientConfig) {
      this.createComponent();
    }
  }

  protected getConfiguration(compType: PageCompType): ComponentConfiguration<any> {
    const config = this.clientConfig.getConfigurationSafe(compType);
    return config || this.componentDeclarationService.createComponentConfigurationForType(compType);
  }

  protected abstract getViewContainerRef(): ViewContainerRef

  protected createComponent(): T {
    this.componentConfig = this.getConfiguration(this.type);
    this.componentRef = this.getViewContainerRef().createComponent<T>(this.componentConfig.component);
    return this.componentRef.instance;
  }

  update(updateFn?: (comp: T) => void, updateArgs?: any) {
    if (this.componentRef) {
      const comp = this.componentRef.instance;
      comp.logger = this.logger;
      comp.sessionObj = this.sharedStateService._sessionObj;
      comp.config = this.componentConfig;
      comp.pageCompType = this.type;
      if (updateFn) {
        updateFn(comp);
      }
      comp.doUpdate?.(updateArgs);
    }
  }
}
